<template>
	<AsiCardDetail :icon="icons.category" :title="$t('ui.terms.hierarchy')"
	               :show-action-button="!readonly" :action-button-disabled="disabled"  @actionButtonClicked="updateDialog.open()">


		<AsiCardDetailEntry :icon="icons.hierarchy" :label="$t('category.terms.path')">
			<AsiBreadcrumbs v-if="path.length > 0" :entries="path" hide-icons/>
		</AsiCardDetailEntry>

		<CategoryUpdateDialogParentCategory v-if="!readonly && updateDialog.isLoaded" :category="category" :open="updateDialog.isOpen"
		                                    @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import {Vue, Component, Prop, Emit} from 'vue-property-decorator';
	import CategoryUpdateDialogDescription from "@/components/category/admin/CategoryUpdateDialogDescription.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import Icon from "@/plugins/icons";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import CategoryUpdateDialogParentCategory from "@/components/category/admin/CategoryUpdateDialogParentCategory.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	import PathEntry from "@/models/PathEntry";
	import CategoryHelper from "@/models/category/CategoryHelper";

	@Component({
		components: {
			AsiBreadcrumbs,
			AsiCardDetailEntry, CategoryUpdateDialogParentCategory, AsiCardDetailEntryTranslatedValue, AsiCardDetail, CategoryUpdateDialogDescription}
	})
	export default class CategoryCardParent extends Vue {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		private get path(): PathEntry[] {
			return CategoryHelper.sortedPath(this.category, true, true);
		}

		@Emit('change')
		public change(): void {
			return;
		}
	}
</script>

<style scoped lang="scss">

</style>
