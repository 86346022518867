<template>
	<div>
		<div class="pa-6 text-center grey lighten-5">
			<AsiBtn :icon="icons.add" @click="addNewItem" class="secondary">
				{{$t('postageBulkPrice.terms.create')}}
			</AsiBtn>
		</div>
		<v-data-table
			:items="model"
			:headers="createHeaders()"
			:items-per-page="-1"
			>

			<template v-slot:item.postage="{ item }">
				<v-text-field ref="postage" class="mt-0"
							  v-model.number="currentEditingItem.postage"
				              v-if="isItemEditable(item)"
				              :rules="item.rules('postage')"
				              :suffix="currencyCode"/>
				<span v-else>{{ $n(item.postage, {key: 'currencyDisplay'}) }}</span>
			</template>

			<template v-slot:item.state="{ item }">
				<AsiSelect v-model="currentEditingItem.state" class="pt-0 mt-0 mb-2"
				           :label="$t('ui.terms.state')"
				           :placeholder="$t('ui.terms.state')"
				           :items="stateOptions"
				           :filled="false"
				           single-line
				           v-if="isItemEditable(item)"/>
				<span v-else>{{ stateLabel(item.state) }}</span>
			</template>

			<template v-slot:item.totalGrossWeightFrom="{ item }">
				<v-text-field ref="totalGrossWeightFrom" class="align-start"
				              v-model.number="currentEditingItem.totalGrossWeightFrom"
				              v-if="isItemEditable(item)"
				              :rules="item.rules('totalGrossWeightFrom')"
				              :suffix="$t('constants.unitKilogram')"/>
				<span v-else>{{ item.totalGrossWeightFrom + $t('constants.unitKilogram') }}</span>
			</template>

			<template v-slot:item.totalGrossWeightTo="{ item }">
				<v-text-field v-model.number="currentEditingItem.totalGrossWeightTo" class="mt-0"
				              v-if="isItemEditable(item)"
				              :suffix="$t('constants.unitKilogram')"/>
				<span v-else>{{ item.totalGrossWeightTo !== null
									? item.totalGrossWeightTo + $t('constants.unitKilogram')
									: null }}</span>
			</template>

			<template v-slot:item.validFrom="{ item }">
				<AsiDatePickerCombined v-model="currentEditingItemValidFrom" class="mt-0"
				                       v-if="isItemEditable(item)"
				                       :max-date="currentEditingItemValidTo === null ? undefined : currentEditingItemValidTo"
				                       :label="$t('postageBulkPrice.validFrom')"
				                       clearable
				                       simple/>
				<span v-else>{{  item.validFrom !== null
									? $d(new Date(item.validFrom), 'short')
									: null }}
				</span>
			</template>

			<template v-slot:item.validTo="{ item }">
				<AsiDatePickerCombined v-model="currentEditingItemValidTo" class="mt-0"
				                       v-if="isItemEditable(item)"
				                       :min-date="currentEditingItemValidFrom === null ? undefined : currentEditingItemValidFrom"
				                       :label="$t('postageBulkPrice.validTo')"
				                       clearable
				                       simple/>
				<span v-else>{{  item.validTo !== null
						? $d(new Date(item.validTo), 'short')
						: null }}
				</span>
			</template>

			<template v-slot:item.actions="{ item, index }">
				<div v-if="isItemEditable(item)" class="d-flex flex-row flex-wrap">
					<AsiBtn :icon="icons.close" small @click="cancel"/>
					<AsiBtn :icon="icons.yes" small @click="save(currentEditingItem, index)"/>
				</div>
				<div v-else class="d-flex flex-row flex-wrap">
					<AsiBtn :icon="icons.edit" small @click="editItem(item)"/>
					<AsiBtn :icon="icons.delete" small @click="deleteItem(item)"/>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script lang="ts">
	import {Vue, Component, Prop, Emit, Watch} from 'vue-property-decorator';
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import PostageBulkPriceCreate from "@/models/shipment-type/PostageBulkPriceCreate";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import DateTimeHelper from "@/helpers/DateTimeHelper";
	import EnumHelper from "@/helpers/EnumHelper";
	import {PostageBulkPriceState} from "@/helpers/constants";
	import AsiSelect from "@/components/common/AsiSelect";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import {IPostageBulkPriceUpdate} from "@/models/shipment-type/PostageBulkPriceAdminModels";
	import PostageBulkPriceUpdate from "@/models/shipment-type/PostageBulkPriceUpdate";

	@Component({
		components: {AsiCheckbox, AsiSelect, AsiDatePickerCombined, AsiBtn}
	})
	export default class PostageBulkPricesList extends Vue {

		private icons = Icon;

		@Prop({required: true})
		public value!: IPostageBulkPriceUpdate[];

		@Prop({type: Boolean, default: true})
		public showActions!: boolean;

		private createHeaders(): AsiListTableHeader[] {
			const ret: AsiListTableHeader[] = [
				new AsiListTableHeader(this.$t('postageBulkPrice.singular'), 'postage', true, true, 'start', '8rem'),
				new AsiListTableHeader(this.$t('ui.terms.state'), 'state', true, true, 'start', '8rem'),
				new AsiListTableHeader(`${this.$t('postageBulkPrice.totalGrossWeightFrom')} >=`, 'totalGrossWeightFrom', true, true, 'start','10rem'),
				new AsiListTableHeader(`${this.$t('postageBulkPrice.totalGrossWeightTo')} <`, 'totalGrossWeightTo', true, true, 'start', '10rem'),
				new AsiListTableHeader(this.$t('postageBulkPrice.validFrom'), 'validFrom', true, true, 'start', '12rem'),
				new AsiListTableHeader(this.$t('postageBulkPrice.validTo'), 'validTo', true, true, 'start', '12rem'),
			];

			if (this.showActions) {
				ret.push(
					new AsiListTableHeader('', 'actions', false, false, 'end', '8rem'),
				);
			}

			return ret;
		}

		private model: PostageBulkPriceUpdate[] | null = null;
		private currentEditingItem: PostageBulkPriceUpdate | null = null;
		private currentEditingItemIndex: number | null = null;

		private isValid() {
			const totalGrossWeightFromField = this.$refs.totalGrossWeightFrom as HTMLFormElement;
			const postageField = this.$refs.postage as HTMLFormElement;

			return totalGrossWeightFromField.validate() && postageField.validate();
		}

		private set currentEditingItemValidTo(date: string | null){
			if (this.currentEditingItem === null || date === null) return;

			const dateTo = new Date(date);
			dateTo.setHours(0, 0, 0);
			this.currentEditingItem.validTo = DateTimeHelper.toISODateTimeString(dateTo);
		}

		private get currentEditingItemValidTo(){
			if (this.currentEditingItem === null) return null;

			return this.currentEditingItem.validTo;
		}

		private set currentEditingItemValidFrom(date: string | null){
			if (this.currentEditingItem === null || date === null) return;

			const dateFrom = new Date(date);
			dateFrom.setHours(0, 0, 0);
			this.currentEditingItem.validFrom = DateTimeHelper.toISODateTimeString(dateFrom);
		}

		private get currentEditingItemValidFrom(){
			if (this.currentEditingItem === null) return null;

			return this.currentEditingItem.validFrom;
		}

		private editItem (item: PostageBulkPriceUpdate) {
			if (this.model === null) return;

			this.currentEditingItemIndex = this.model.indexOf(item);
			this.currentEditingItem = this.cloneItem(item);
		}

		private deleteItem(item: PostageBulkPriceUpdate){
			if (this.model === null) return;

			this.model = this.model.filter(p => p != item);
			this.emitUpdate();
		}

		private addNewItem(){
			if (this.model === null) return;

			let emptyPostageBulkPrice = new PostageBulkPriceCreate();
			let postageBulkPrice = new PostageBulkPriceUpdate(emptyPostageBulkPrice);
			this.model.push(postageBulkPrice);
			this.editItem(postageBulkPrice);
		}

		private cancel(){
			this.currentEditingItemIndex = -1;
		}

		private save(item: PostageBulkPriceUpdate, index: number){
			if (this.model === null) return;

			if (this.isValid()){
				this.model.splice(index, 1, item);
				this.emitUpdate();
				this.cancel();
			}
		}

		public cloneItem(item: PostageBulkPriceUpdate): PostageBulkPriceUpdate {
			return new PostageBulkPriceUpdate(item);
		}

		private get stateOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(PostageBulkPriceState, true);
		}

		private stateLabel(bulkPriceState: PostageBulkPriceState): string|null {
			return EnumHelper.textFromValue(PostageBulkPriceState, bulkPriceState, true);
		}

		private isItemEditable(item: PostageBulkPriceUpdate){
			return this.currentEditingItemIndex === this.getIndexOfPostagePrice(item);
		}

		private getIndexOfPostagePrice(item: PostageBulkPriceUpdate){
			if (this.model === null) return;
			return this.model.indexOf(item);
		}

		private get currencyCode(): string {
			return 'CHF';
		}

		@Emit('update')
		emitUpdate() {
			return this.model;
		}

		@Watch('value', { immediate: true, deep: true })
		onValueChanged(newVal: PostageBulkPriceUpdate[]) {
			this.model = newVal;
		}
	}
</script>


<style scoped lang="scss">

</style>
